import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import Row from './_Row'

const useStyles = makeStyles((theme) => ({
  cell_sm: {
    width: 'calc(100% - 50px)',
    padding: '7px',
  },
  cell: {
    width: 'calc(100% - 115px)',
    padding: '7px',
  },
}));

export default ({ results, onSave }) => {
  const classes = useStyles();
  const [local_results, setLocalResults] = useState([])
  const [show_other_uom, setShowOtherUom] = useState(false)

  useEffect(() => {
    setLocalResults(results);
  }, [results]);

  const onSaveLocal = (res) => {
    onSave()
  }

  const onChange = () => {
    setShowOtherUom(!show_other_uom)
  }

  return(
    <>
      <FormGroup>
        <FormControlLabel control={<Checkbox onChange={onChange} />} label="Show other UOM Equivalents" />
      </FormGroup>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell_sm}>ID</TableCell>
              <TableCell className={classes.cell}>Item Number</TableCell>
              <TableCell className={classes.cell}>Description</TableCell>
              <TableCell className={classes.cell}>Invoice UOM</TableCell>
              <TableCell className={classes.cell}>Vendor</TableCell>
              <TableCell className={classes.cell}>Equivalent UOM Instance</TableCell>
              { show_other_uom && <TableCell className={classes.cell}>Equivalent UOM Basket</TableCell> }
              { show_other_uom && <TableCell className={classes.cell}>Equivalent UOM Cogswell</TableCell> }
              { show_other_uom && <TableCell className={classes.cell}>Equivalent UOM Chouxbox</TableCell> }
              <TableCell className={classes.cell_button}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((result) => (
              <Row data={result} onSave={onSaveLocal} show_other_uom={show_other_uom} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}