import React, { useState } from "react";
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Button,
  TextField,
  List,
  ListItem,
} from '@mui/material';

import { doAuthenticatedPostFormData } from '../../../actions/_methods';
import { useSnackbar } from 'notistack';
import PasteClipboardImage from './_PasteClipboardImage';

const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: '10px',
    width: '100%',
    border: '1px solid #2976d2'
  },
  content: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  textfield: {
    width: '330px'
  }
}));


const Input = styled('input')({
  display: 'none',
});

const ADD_URL = '/uom_knowledge_bases';

export default ({ onClose }) => {
  const classes = useStyles();

  const [description,               setDescription]           = useState('');
  const [item_number,               setItemNumber]            = useState('');
  const [invoice_uom,               setInvoiceUom]            = useState('');
  const [equivalent_uom_instance,   setEquivalentUomInstance] = useState('');
  const [equivalent_uom_basket,     setEquivalentUomBasket]   = useState('');
  const [equivalent_uom_cogswell,   setEquivalentUomCogswell] = useState('');
  const [equivalent_uom_chouxbox,   setEquivalentUomChouxbox] = useState('');
  const [vendor,                    setVendor]                = useState('');
  const { enqueueSnackbar,          closeSnackbar }           = useSnackbar();

  const onCancel = () => {
    clearValues()
    onClose()
  }

  const clearValues = () => {
    setDescription('')
    setItemNumber('')
    setInvoiceUom('')
    setEquivalentUomInstance('')
    setEquivalentUomBasket('')
    setEquivalentUomCogswell('')
    setEquivalentUomChouxbox('')
    setVendor('')
  }

  const onPasteImage = (files) => {
    document.querySelector('#file_attachment').files = files;
  }

  const onAdd = () => {
    if(isValid()) {
      var fileInput = document.querySelector('#file_attachment')
      var cleanData = getCleanData();
      var formdata = new FormData();
      formdata.append("description",              cleanData.description);
      formdata.append("item_number",              cleanData.item_number);
      formdata.append("invoice_uom",              cleanData.invoice_uom);
      formdata.append("equivalent_uom_instance",  cleanData.equivalent_uom_instance);
      formdata.append("equivalent_uom_basket",    cleanData.equivalent_uom_basket);
      formdata.append("equivalent_uom_cogswell",  cleanData.equivalent_uom_cogswell);
      formdata.append("equivalent_uom_chouxbox",  cleanData.equivalent_uom_chouxbox);
      formdata.append("vendor",                   cleanData.vendor);
      
      if (fileInput.files[0]) { 
        formdata.append("file", fileInput.files[0], fileInput.files[0].name);
      }

      doAuthenticatedPostFormData(ADD_URL, formdata, {
        success: onSuccess,
        general: onSuccess,
        error: onError,
      })
    } else {
      enqueueSnackbar('Please complete field', { variant: 'error' })
    }
  }

  const onSuccess = (res) => {
    enqueueSnackbar('Successfully added UOM!', { variant: 'success' })
    clearValues()
    onClose()
  }

  const onError = (res) => {
    enqueueSnackbar('Ooops... Please try again...', { variant: 'error' })
  }

  const getCleanData = () => {
    return({
      description: description.trim(),
      item_number: item_number.trim(),
      invoice_uom: invoice_uom.trim(),
      equivalent_uom_instance: equivalent_uom_instance.trim(),
      equivalent_uom_basket: equivalent_uom_basket.trim(),
      equivalent_uom_cogswell: equivalent_uom_cogswell.trim(),
      equivalent_uom_chouxbox: equivalent_uom_chouxbox.trim(),
      vendor: vendor.trim(),
    })
  }

  const isValid = () => {
    return (description.trim() !== '' && invoice_uom.trim() !== '' && (equivalent_uom_instance.trim() !== '' || equivalent_uom_basket.trim() !== '' || equivalent_uom_cogswell.trim() !== '' || equivalent_uom_chouxbox.trim() !== ''))
  }

  return(
    <Card className={classes.box} raised={true}>
      <CardHeader title="Add UOM" />
      <CardContent>
        <List className={classes.content}>
          <TextField className={classes.textfield} label="Item Number"              variant="outlined" value={item_number}              onChange={(e) => setItemNumber(e.target.value)} />
          <TextField className={classes.textfield} label="Description"              variant="outlined" value={description}              onChange={(e) => setDescription(e.target.value)} />
          <TextField className={classes.textfield} label="Invoice UOM"              variant="outlined" value={invoice_uom}              onChange={(e) => setInvoiceUom(e.target.value)} />
          <TextField className={classes.textfield} label="Vendor"                   variant="outlined" value={vendor}                   onChange={(e) => setVendor(e.target.value)} />
        </List>
        <List className={classes.content}>
          <TextField className={classes.textfield} label="Equivalent UOM Instance"  variant="outlined" value={equivalent_uom_instance}  onChange={(e) => setEquivalentUomInstance(e.target.value)} />
          <TextField className={classes.textfield} label="Equivalent UOM Basket"    variant="outlined" value={equivalent_uom_basket}    onChange={(e) => setEquivalentUomBasket(e.target.value)} />
          <TextField className={classes.textfield} label="Equivalent UOM Cogswell"  variant="outlined" value={equivalent_uom_cogswell}  onChange={(e) => setEquivalentUomCogswell(e.target.value)} />
          <TextField className={classes.textfield} label="Equivalent UOM Chouxbox"  variant="outlined" value={equivalent_uom_chouxbox}  onChange={(e) => setEquivalentUomChouxbox(e.target.value)} />
        </List>
        <List>
          <PasteClipboardImage onPasteImage={onPasteImage}/>
          <Input accept="*" id="file_attachment" type="file" />
        </List>
      </CardContent>
      <CardContent className={classes.footer}>
        <Button variant="contained" onClick={onAdd}>Add UOM</Button>
        <Button onClick={onCancel}>Cancel</Button>
      </CardContent>
    </Card>
  )
}