import React from 'react';
import _ from 'lodash';
import TextField from '@mui/material/TextField';

const STYLES = {
  element: {
    marginRight: '10px',
    width: '100%',
  }
}

export default ({column, label, data, onChange}) => {

  const onChangeLocal = (e) => {
    let new_data = {}
    new_data[column] = e.target.value;
    onChange(new_data);
  }

  return (<>
    <TextField
      defaultValue={data[column]}
      size="small"
      label={label}
      variant="outlined"
      onChange={onChangeLocal}
      className={STYLES.element}
    />
  </>)
}
