import React, {useState} from "react";
import { makeStyles } from '@mui/styles';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Button,
  TextField,
} from '@mui/material';

import { doAuthenticatedPost } from '../../../actions/_methods';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: '10px',
    width: '1000px',
    border: '1px solid #2976d2'
  },
  content: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const ADD_URL = '/gl_codes';

export default ({onClose}) => {
  const classes = useStyles();

  const [description, setDescription] = useState('');
  const [gl_account,  setGlAccount]   = useState('');
  const [location,    setLocation]    = useState('');
  const [notes,       setNotes]       = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onCancel = () => {
    clearValues()
    onClose()
  }

  const clearValues = () => {
    setDescription('')
    setGlAccount('')
    setLocation('')
    setNotes('')
  }

  const onAdd = () => {
    if(isValid()) {
      doAuthenticatedPost(ADD_URL, getCleanData(), {
        success: onSuccess,
        general: onSuccess,
        error: onError,
      })
    } else {
      enqueueSnackbar('Please complete field', { variant: 'error' })
    }
  }

  const onSuccess = (res) => {
    enqueueSnackbar('Successfully added GL Code!', { variant: 'success' })
    clearValues()
    onClose()
  }

  const onError = (res) => {
    enqueueSnackbar('Ooops... Please try again...', { variant: 'error' })
  }

  const getCleanData = () => {
    return({
      description: description.trim(),
      gl_account: gl_account.trim(),
      location: location.trim(),
      notes: notes.trim(),
    })
  }

  const isValid = () => {
    return (description.trim() !== '' && gl_account.trim() !== '' & location.trim() !== '')
  }

  return(
    <Card className={classes.box} raised={true}>
      <CardHeader title="Add GL Code" />
      <CardContent className={classes.content}>
        <TextField label="Description"   variant="outlined" value={description}   onChange={(e) => setDescription(e.target.value)} />
        <TextField label="GL Account"    variant="outlined" value={gl_account}    onChange={(e) => setGlAccount(e.target.value)} />
        <TextField label="Location"      variant="outlined" value={location}      onChange={(e) => setLocation(e.target.value)} />
        <TextField label="Notes"         variant="outlined" value={notes}         onChange={(e) => setNotes(e.target.value)} />
      </CardContent>
      <CardContent className={classes.footer}>
        <Button variant="contained" onClick={onAdd}>Add GL Code</Button>
        <Button onClick={onCancel}>Cancel</Button>
      </CardContent>
    </Card>
  )
}