import React, { useState } from "react";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  paste_box: {
    margin: '0',
    padding: '1.6rem',
    border: '0.2rem dotted gray',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1',
    transition: 'color 0.2s ease -in -out',
  },
}));

export default ({ onPasteImage }) => {
  const classes = useStyles();
  const [copiedImageURL, setCopiedImageURL] = useState('')
  const [files, setFiles] = useState([])

  const handlePaste = (event) => {
    if (event.clipboardData) {
      const url = handleTransformDataTransferIntoURL(event.clipboardData)
      if (url) setCopiedImageURL(url) 
    }
  }

  const handleTransformDataTransferIntoURL = (dataTransfer) => {
    const [firstItem] = dataTransfer.items
    const blob = firstItem.getAsFile()

    if (blob !== null) {
      const url = URL.createObjectURL(blob)

      let file = new File([blob], "file-attachment", { type: "image/jpeg", lastModified: new Date().getTime() }, 'utf-8');
      let container = new DataTransfer();
      container.items.add(file);
      onPasteImage(container.files)

      return url
    } else {
      return null
    }
    
  }

  return (
    <div className={classes.paste_box} onPaste={handlePaste}>
      {copiedImageURL ? (
        <img src={copiedImageURL} width='1050px' />
      ) : (
        <div>Paste the screenshot here</div>
      )}
    </div>
  )
}