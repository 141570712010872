import React, { useState, useEffect } from "react";
import {
  TableCell,
  Button,
} from '@mui/material';
import Textfield from "../shared/edit_string";
import { doAuthenticatedPut } from '../../../actions/_methods';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  cell: {
    paddingRight: '7px',
    paddingLeft: '7px',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
}));

const UOM_URL = '/gl_codes';

export default ({ data, onSave, onCancel }) => {
  const classes = useStyles();
  const [local_data, setLocalData] = useState({ ...data });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    setLocalData({ ...data });
  }, [data]);

  const onClickSave = () => {
    let edit_url = UOM_URL + '/' + data['id']

    if (isValid()) {
      doAuthenticatedPut(edit_url, getCleanData(), {
        success: onSuccess,
        general: onSuccess,
        error: onError,
      })
    } else {
      enqueueSnackbar('Please complete the required fields', { variant: 'error' });
    }
  }

  const isValid = () => {
    return (local_data['description'].trim() !== '' && local_data['gl_account'].trim() !== '' & local_data['location'].trim() !== '')
  }

  const getCleanData = () => {
    return ({
      description: local_data['description'].trim(),
      gl_account: local_data['gl_account'].trim(),
      location: local_data['location'].trim(),
      notes: local_data['notes'].trim(),
    })
  }

  const onSuccess = (res) => {
    onSave()
    enqueueSnackbar('Successfully edited GL Code!', { variant: 'success' })
  }

  const onError = (res) => {
    enqueueSnackbar('Ooops... Please try again...', { variant: 'error' })
  }

  const onClickCancel = (e) => {
    onCancel()
  }

  const onChange = (new_data) => {
    setLocalData({ ...local_data, ...new_data })
  }

  return(
    <>
      <TableCell className={classes.cell} component="th" scope="row">{data.id}</TableCell>
      <TableCell className={classes.cell}><Textfield column='description' label='Description' data={local_data} onChange={onChange} /></TableCell>
      <TableCell className={classes.cell}><Textfield column='gl_account' label='GL Account' data={local_data} onChange={onChange} /></TableCell>
      <TableCell className={classes.cell}><Textfield column='location' label='Location' data={local_data} onChange={onChange} /></TableCell>
      <TableCell className={classes.cell}><Textfield column='notes' label='Notes' data={local_data} onChange={onChange} /></TableCell>
      <TableCell className={classes.cell}>
        <Button variant="contained" color="primary" size="small" disabled={false} onClick={onClickSave}>
          Save
        </Button>
        &nbsp;
        <Button variant="contained" size="small" disabled={false} onClick={onClickCancel}>
          Cancel
        </Button>
      </TableCell>
    </>
  )
}