import React, { useState, useEffect } from "react";
import DisplayUom from './_Display'
import EditUom from './_Edit'

export default ({ data, onSave, show_other_uom }) => {
  const [show_edit, setShowEdit] = useState(false)

  useEffect(() => {
  }, [show_edit]);

  const onSaveLocal = (res) => {
    setShowEdit(false)
    onSave()
  }

  const onDeleteLocal = (res) => {
    onSave()
  }

  return(
    <>
      {show_edit && <EditUom data={data} onSave={onSaveLocal} onCancel={() => setShowEdit(false)} show_other_uom={show_other_uom} /> }
      {!show_edit && <DisplayUom data={data} onClickEdit={() => setShowEdit(true)} onDelete={onDeleteLocal} show_other_uom={show_other_uom} /> }
    </>
  )
}