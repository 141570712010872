import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Popover,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { doAuthenticatedDelete } from '../../../actions/_methods';
import { useSnackbar } from 'notistack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InfoIcon from '@mui/icons-material/Info';

import { makeStyles } from '@mui/styles';
const UOM_URL = '/uom_knowledge_bases';

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: '7px',
  },
  list_item: {
    paddingTop: '0',
    paddingBottom: '0',
  },
  info_primary: {
    fontSize: '10px',
    fontStyle: 'italic',
  },
  info_secondary: {
    fontSize: '10px',
  },
  attachment: {
    colSpan: '10',
    padding: '0'
  },
  attached_image: {
    border: '1px solid #aaa',
    padding: '10px',
    backgroundColor: '#eaeaea',
    margin: '10px',
    width: '1050px',
  },
}));

export default ({ data, onClickEdit, onDelete, show_other_uom }) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onClickDelete = () => {
    let url = UOM_URL + '/' + data['id']

    if (window.confirm('Are you sure you want to delete this UOM?')) {
      doAuthenticatedDelete(url, {}, {
        success: onSuccess,
        general: onSuccess,
        error: onError,
      })
    }
  }

  const onSuccess = (res) => {
    onDelete()
    enqueueSnackbar('Successfully deleted UOM!', { variant: 'success' })
  }

  const onError = (res) => {
    enqueueSnackbar('Ooops... Please try again...', { variant: 'error' })
  }

  return(
    <>
      <TableRow
        key={`tr_${data.id}`}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell className={classes.cell} component="th" scope="row">{data.id}</TableCell>
        <TableCell className={classes.cell}>{data.item_number}</TableCell>
        <TableCell className={classes.cell}>{data.description}</TableCell>
        <TableCell className={classes.cell}>{data.invoice_uom}</TableCell>
        <TableCell className={classes.cell}>{data.vendor}</TableCell>
        <TableCell className={classes.cell}>{data.equivalent_uom_instance}</TableCell>
        {show_other_uom && <TableCell className={classes.cell}>{data.equivalent_uom_basket}</TableCell> }
        {show_other_uom && <TableCell className={classes.cell}>{data.equivalent_uom_cogswell}</TableCell> }
        {show_other_uom && <TableCell className={classes.cell}>{data.equivalent_uom_chouxbox}</TableCell> }
        <TableCell className={classes.cell}>
          <InfoIcon
            aria-describedby={id}
            onClick={handleClick}
            color="secondary" />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <List style={{ width: '250px' }}>
              {data.versions.map((v) => {
                return (
                  <ListItem className={classes.list_item}>
                    <ListItemText classes={{ primary: classes.info_primary, secondary: classes.info_secondary }} primary={v['event'] + 'd by ' + v['user']} secondary={v['date']} />
                  </ListItem>
                )
              })}
            </List>

          </Popover>
          &nbsp;
          <EditIcon onClick={onClickEdit} color="primary" />
          &nbsp;
          <DeleteForeverIcon color="error" onClick={onClickDelete} />
        </TableCell>
      </TableRow>
      {
        (data.attachment_url !== null) &&
        <TableRow>
          <TableCell className={classes.attachment} colSpan='10'>
            <img src={data.attachment_url} className={classes.attached_image} />
          </TableCell>
        </TableRow>
      }
    </>
  )
}