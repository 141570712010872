import React from "react";
import {
  TableCell,
  Button,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { doAuthenticatedDelete } from '../../../actions/_methods';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: '7px',
  },
}));

const UOM_URL = '/gl_codes';

export default ({ data, onClickEdit, onDelete }) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onClickDelete = () => {
    let url = UOM_URL + '/' + data['id']

    if (window.confirm('Are you sure you want to delete this GL Code?')) {
      doAuthenticatedDelete(url, {}, {
        success: onSuccess,
        general: onSuccess,
        error: onError,
      })
    }
  }

  const onSuccess = (res) => {
    onDelete()
    enqueueSnackbar('Successfully deleted GL Code!', { variant: 'success' })
  }

  const onError = (res) => {
    enqueueSnackbar('Ooops... Please try again...', { variant: 'error' })
  }

  return(
    <>
      <TableCell className={classes.cell} component="th" scope="row">{data.id}</TableCell>
      <TableCell className={classes.cell}>{data.description}</TableCell>
      <TableCell className={classes.cell}>{data.gl_account}</TableCell>
      <TableCell className={classes.cell}>{data.location}</TableCell>
      <TableCell className={classes.cell}>{data.notes}</TableCell>
      <TableCell className={classes.cell}>
        <EditIcon onClick={onClickEdit} color="primary" />
        &nbsp;
        <DeleteForeverIcon color="error" onClick={onClickDelete} />
      </TableCell>
    </>
  )
}