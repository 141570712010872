import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import Row from './_Row'

const useStyles = makeStyles((theme) => ({
  cell_sm: {
    width: '5%',
    padding: '7px',
  },
  cell: {
    width: '19%',
    padding: '7px',
  },
}));

export default ({ results, onSave }) => {
  const classes = useStyles();
  const [local_results, setLocalResults] = useState([])

  useEffect(() => {
    setLocalResults(results);
  }, [results]);

  const onSaveLocal = (res) => {
    onSave()
  }
  
  return(
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 900 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell_sm}>ID</TableCell>
            <TableCell className={classes.cell}>Description</TableCell>
            <TableCell className={classes.cell}>GL Account</TableCell>
            <TableCell className={classes.cell}>Location</TableCell>
            <TableCell className={classes.cell}>Notes</TableCell>
            <TableCell className={classes.cell}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((result) => (
            <TableRow
              key={`tr_${result.id}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <Row data={result} onSave={onSaveLocal} />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}