import React, {useState} from "react";
import { makeStyles } from '@mui/styles';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Button,
  TextField,
} from '@mui/material';

import AddGlCode from './_AddGlCode';
import Results from './_Results';
import { useSnackbar } from 'notistack';

import { doAuthenticatedGet } from '../../../actions/_methods';

const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: '10px',
    width: '1000px',
    border: '1px solid #9f9f9f',
  },
  search_box: {
    display: 'flex',
    justifyContent: 'space-around',
    verticalAlign: 'middle'
  }
}));

const SEARCH_URL = '/gl_codes';

export default () => {
  const classes = useStyles();
  const [show_add_gl_code, setShowAddGlCode] = useState(false)
  const [search_text, setSearchText] = useState('')
  const [results, setResults] = useState([])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  
  const doSearch = () => {
    doAuthenticatedGet(SEARCH_URL, {query: search_text}, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
    })
  }

  const onSuccess = (res) => {
    setResults(res.gl_codes)
  }

  const onError = (res) => {
    enqueueSnackbar('Ooops... Please try again...', { variant: 'error' })
  }

  const onSave = (res) => {
    doSearch()
  }

  const AddGlCodeButton = () => (
    <Button
      variant="contained"
      size="small"
      color={show_add_gl_code ? 'secondary' : 'primary'}
      onClick={() => setShowAddGlCode(!show_add_gl_code)}
    >
      {show_add_gl_code ? 'Close Add GL Code' : 'Add GL Code'}
    </Button>
  )

  const isEnter = (e) => {
    let key_code = (e.which || e.keyCode)
    return(key_code === 13 && !e.shiftKey)
  }

  return(
    <>
      {show_add_gl_code && <AddGlCode onClose={() => setShowAddGlCode(false)} />}

      <Card className={classes.box}>
        <CardHeader title="GL Code" action={<AddGlCodeButton />} />
        <CardContent className={classes.search_box}>
          <TextField
            label="Search by Description or Location"
            variant="outlined"
            fullWidth
            value={search_text}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyPress={(e) => {
              if(isEnter(e)) {
                e.preventDefault();
                doSearch();
                return false
              }
            }}
          />
          <Button variant="contained" onClick={doSearch}>Search</Button>
        </CardContent>
        <CardContent>
          <Results results={results} onSave={onSave} />
        </CardContent>
      </Card>
    </>
  )
}