import React, { useState, useEffect } from "react";
import {
  TableRow,
  TableCell,
  Button,
  List,
  ListItem,
} from '@mui/material';
import Textfield from "../shared/edit_string";
import { doAuthenticatedPutFormData } from '../../../actions/_methods';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';

import { makeStyles } from '@mui/styles';
import PasteClipboardImage from './_PasteClipboardImage';

const useStyles = makeStyles((theme) => ({
  cell: {
    paddingRight: '7px',
    paddingLeft: '7px',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  list_item: {
    paddingTop: '2px',
    paddingBottom: '1px'
  },
  attachment: {
    colSpan: '10',
    padding: '0'
  },
  attached_image: {
    border: '1px solid #aaa',
    padding: '10px',
    backgroundColor: '#eaeaea',
    margin: '10px',
    width: '1050px',
  },
}));

const Input = styled('input')({
  display: 'none',
});

const UOM_URL = '/uom_knowledge_bases';

export default ({ data, onSave, onCancel, show_other_uom }) => {
  const classes = useStyles();
  const [local_data, setLocalData] = useState({ ...data });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    setLocalData({ ...data });
  }, [data]);

  const onClickSave = () => {
    let edit_url = UOM_URL + '/' + data['id']

    if (isValid()) {
      var fileInput = document.querySelector('#file_attachment_' + data.id)
      var cleanData = getCleanData();
      var formdata = new FormData();
      formdata.append("description",              cleanData.description);
      formdata.append("item_number",              cleanData.item_number);
      formdata.append("invoice_uom",              cleanData.invoice_uom);
      formdata.append("equivalent_uom_instance",  cleanData.equivalent_uom_instance);
      formdata.append("equivalent_uom_basket",    cleanData.equivalent_uom_basket);
      formdata.append("equivalent_uom_cogswell",  cleanData.equivalent_uom_cogswell);
      formdata.append("equivalent_uom_chouxbox",  cleanData.equivalent_uom_chouxbox);
      formdata.append("vendor", cleanData.vendor);
      
      if (fileInput.files[0]) {
        formdata.append("file", fileInput.files[0], fileInput.files[0].name);
      }

      doAuthenticatedPutFormData(edit_url, formdata, {
        success: onSuccess,
        general: onSuccess,
        error: onError,
      })
    } else {
      enqueueSnackbar('Please complete the required fields', { variant: 'error' });
    }
  }

  const isValid = () => {
    return (
            local_data['description'].trim() !== '' &&
            local_data['invoice_uom'].trim() !== '' &&
            (
              (local_data['equivalent_uom_instance'] && local_data['equivalent_uom_instance'].trim() !== '') ||
              (local_data['equivalent_uom_basket'] && local_data['equivalent_uom_basket'].trim() !== '') ||
              (local_data['equivalent_uom_cogswell'] && local_data['equivalent_uom_cogswell'].trim() !== '') ||
              (local_data['equivalent_uom_chouxbox'] && local_data['equivalent_uom_chouxbox'].trim() !== '')
            )
          )
  }

  const getCleanData = () => {
    return ({
      item_number: local_data['item_number'].trim(),
      description: local_data['description'].trim(),
      invoice_uom: local_data['invoice_uom'].trim(),
      equivalent_uom_instance: (local_data['equivalent_uom_instance'] ? local_data['equivalent_uom_instance'].trim() : ''),
      equivalent_uom_basket: (local_data['equivalent_uom_basket'] ? local_data['equivalent_uom_basket'].trim() : ''),
      equivalent_uom_cogswell: (local_data['equivalent_uom_cogswell'] ? local_data['equivalent_uom_cogswell'].trim() : ''),
      equivalent_uom_chouxbox: (local_data['equivalent_uom_chouxbox'] ? local_data['equivalent_uom_chouxbox'].trim() : '' ),
      vendor: local_data['vendor'].trim(),
    })
  }

  const onSuccess = (res) => {
    onSave()
    enqueueSnackbar('Successfully edited UOM!', { variant: 'success' })
  }

  const onError = (res) => {
    enqueueSnackbar('Ooops... Please try again...', { variant: 'error' })
  }

  const onClickCancel = (e) => {
    onCancel()
  }

  const onChange = (new_data) => {
    setLocalData({ ...local_data, ...new_data })
  }

  const onPasteImage = (files) => {
    document.querySelector('#file_attachment_' + data.id).files = files;
  }

  return(
    <>
    <TableRow
      key={`tr_${data.id}`}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
    
      <TableCell className={classes.cell} component="th" scope="row">{data.id}</TableCell>
      <TableCell className={classes.cell}><Textfield column='item_number' label='Item Number' data={local_data} onChange={onChange} /></TableCell>
      <TableCell className={classes.cell}><Textfield column='description' label='Description' data={local_data} onChange={onChange} /></TableCell>
      <TableCell className={classes.cell}><Textfield column='invoice_uom' label='Invoice UOM' data={local_data} onChange={onChange} /></TableCell>
      <TableCell className={classes.cell}><Textfield column='vendor' label='Vendor' data={local_data} onChange={onChange} /></TableCell>
      <TableCell className={classes.cell}><Textfield column='equivalent_uom_instance' label='Equivalent UOM Instance' data={local_data} onChange={onChange} /></TableCell>
      { show_other_uom && <TableCell className={classes.cell}><Textfield column='equivalent_uom_basket' label='Equivalent UOM Basket' data={local_data} onChange={onChange} /></TableCell> }
      { show_other_uom && <TableCell className={classes.cell}><Textfield column='equivalent_uom_cogswell' label='Equivalent UOM Cogswell' data={local_data} onChange={onChange} /></TableCell> }
      { show_other_uom && <TableCell className={classes.cell}><Textfield column='equivalent_uom_chouxbox' label='Equivalent UOM Chouxbox' data={local_data} onChange={onChange} /></TableCell> }
      <TableCell className={classes.cell}>
        <List>
          <ListItem className={classes.list_item}>
            <Button variant="contained" color="primary" size="small" disabled={false} onClick={onClickSave}>
              Save
            </Button>
          </ListItem>
          <ListItem className={classes.list_item}>
            <Button variant="contained" size="small" disabled={false} onClick={onClickCancel}>
              Cancel
            </Button>
          </ListItem>
        </List>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell className={classes.attachment} colSpan='10'>
        <PasteClipboardImage onPasteImage={onPasteImage} />
        <Input accept="*" id={`file_attachment_${data.id}`} type="file" />
      </TableCell>
    </TableRow>
    {
      (data.attachment_url !== null) &&
      <TableRow>
        <TableCell className={classes.attachment} colSpan='10'>
          <img src={data.attachment_url} className={classes.attached_image} />
        </TableCell>
      </TableRow>
    }
    </>
  )
}