import React from 'react';
import { Router } from '@reach/router';
import { Provider } from 'react-redux';
import store from '../store';
import { GlobalCtxProvider } from '../features/context/global';
import { SnackbarProvider } from 'notistack';
import { FirmTasksCtxProvider } from '../features/context/firm_tasks';
import { PermissionCtxProvider } from '../features/context/permission';
import { ReferencesCtxProvider } from '../features/context/references';

import AppBar from '../features/_layout/components/AppBar';
import SideMenu from '../features/_layout/components/SideMenu';
import Layout from '../features/_layout/Layout';

import Uoms from '../features/tools/uoms/';
import Glcodes from '../features/tools/gl_codes/';

export default () => {
  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <GlobalCtxProvider>
          <ReferencesCtxProvider>
          <PermissionCtxProvider>
            <FirmTasksCtxProvider>
              <Layout AppBar={AppBar} SideMenu={SideMenu}>
                <Router basepath="tools">
                  <Glcodes path="/gl_codes" />
                  <Uoms path="/uoms" />
                  <Uoms path="/" />
                </Router>
              </Layout>
            </FirmTasksCtxProvider>
          </PermissionCtxProvider>
        </ReferencesCtxProvider>
        </GlobalCtxProvider>
      </SnackbarProvider>
    </Provider>
  );
};
